import React from 'react'
import { Button, Grid, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { KeyboardArrowLeft } from '@mui/icons-material'

const usePandiumTopToolbarStyles = makeStyles((theme) => {
    return {
        button: {
            fontFamily: 'RobotoCondensedBold',
            color: '#353DDF',
            fontSize: '15px',
            letterSpacing: '2px',
            textAlign: 'center',
            lineHeight: '14px',
        },
        pageTitle: {
            fontFamily: 'RobotoCondensedBold',
            margin: '0px 0 16px 0px',
        },
        idText: {
            margin: '0px 0 0 27px',
            color: '#6d6d6d',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
    }
})

/**
 * A pandium themed top tool bar with a built in back button that actually brings you
 * back from whence you came.
 * @param children - the tool bar buttons
 * @param pageTitle
 * @param name - the small grey name in the toolbar
 * @param handleBack
 * @param backButtonText
 * @param props
 * @returns {*}
 */
export default ({
    children,
    pageTitle,
    name,
    handleBack,
    backButtonText,
    ...props
}) => {
    const classes = usePandiumTopToolbarStyles()
    return (
        <Grid container spacing={3}>
            {handleBack && (
                <Grid item xs={12}>
                    <Button onClick={handleBack} className={classes.button}>
                        <KeyboardArrowLeft />{' '}
                        {backButtonText ? backButtonText : 'BACK'}
                    </Button>
                </Grid>
            )}
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                item
                xs={4}
                wrap="nowrap"
            >
                <Typography variant="h4" className={classes.pageTitle}>
                    {' '}
                    {pageTitle}{' '}
                </Typography>
                {name &&
                    ['My Account', 'Partner Detail'].includes(pageTitle) && (
                        <div className={classes.idText}> NAME: {name} </div>
                    )}
            </Grid>
            <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                item
                xs={8}
                spacing={1}
                wrap="nowrap"
            >
                {children &&
                    React.Children.map(children, (child, idx) => (
                        <Grid item key={idx}>
                            {child}
                        </Grid>
                    ))}
            </Grid>
        </Grid>
    )
}
